import React, { Fragment, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import classNames from "classnames"

import Container from "../../Layout/Container"

import { ProgramName, Brand } from "../../Elements/Brand"
import { animate } from "../../../services/animations"
import styles from "../utils/staticPages.modules.scss"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      stethoscope: file(relativePath: { eq: "stethoscope.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2000
            duotone: { highlight: "#ffffff", shadow: "#3d8b8b" }
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const stethoscope = data.stethoscope.childImageSharp.fluid

  useEffect(() => {
    animate({ target: ".fade-to-right", x: -50 })
  }, [])

  return (
    <Fragment>
      <section className="hero is-medium">
        <div
          className="hero-body"
          style={{
            backgroundImage: `url(${stethoscope.src})`,
            backgroundPosition: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container isCentered desktop={10} fullhd={8}>
            <div className="container fade-to-right">
              {" "}
              <h2>
                Welcome to <br />
                the <ProgramName />
              </h2>
              <h5 className="subtitle">
                The <ProgramName /> is a diagnostic subsidy and adherence{" "}
                <br className="is-hidden-mobile" />
                program from <Brand type="company" />.
              </h5>
              <Link
                to="/diagnostic-subsidy"
                className={classNames(
                  "button mt-1 mr-1 is-primary is-medium",
                  styles["home__buttonIsFullwidth"]
                )}
              >
                Apply for Diagnostic Subsidy
              </Link>
              <p className="mt-1">
                Patients with referral letters from their enrolled doctor can
                <br className="is-hidden-mobile" />
                apply for a diagnostic subsidy for tests from our partner
                <br className="is-hidden-mobile" />
                laboratories.
              </p>
              <Link
                to="/doctor"
                className={classNames(
                  "button mt-2 is-primary is-medium",
                  styles["home__buttonIsFullwidth"]
                )}
              >
                Enroll as Doctor
              </Link>
              <p className="mt-1">
                Become a partner doctor so your patients can avail of diagnostic
                subsidies.
              </p>
            </div>
          </Container>
        </div>
      </section>
    </Fragment>
  )
}

export default HomeBanner
