import React, { useEffect } from "react"
import { navigate } from "gatsby"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Home from "../components/StaticPages/Home/Home"

export default () => {
  useEffect(() => {
    navigate("/doctor")
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <Home />
    </Layout>
  )
}
