import React, { Fragment } from "react"

import HomeBanner from "./HomeBanner"

const Home = () => {
  return (
    <Fragment>
      <HomeBanner />
    </Fragment>
  )
}

export default Home
